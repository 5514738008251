/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://a083tt716d.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://5t52wu5nw5hhvgnmpiaxixmo74.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-yxhgsepjdfd5lfv7ao2kwrf444",
    "aws_cognito_identity_pool_id": "us-east-1:4dd12db6-10ce-40f1-997d-fd403ae92f0f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Mbp7caSxj",
    "aws_user_pools_web_client_id": "pfcss3pslf9th5rep84b72rjt",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "mewappb0c0340356884342a4e3f5f748e233e115126-production",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
